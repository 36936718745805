import React, {FC, useState} from "react";
import {Menu, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";
import dayjs from "dayjs";

type PeriodChangeMenuProps = {
    period: string,
    setPeriod: (value: string) => void,
    name?: string
}

const PeriodChangeMenu: FC<PeriodChangeMenuProps> = ({name="", period, setPeriod}) => {
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [currentItem, setCurrentItem] = useState("last_30_days");
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChangePeriod = (value: string) => {
        let date = dayjs();
        switch (value) {
            case "yesterday":
                date = dayjs().add(-1, "day");
                break;
            case "today":
                date = dayjs();
                break;
            case "last_7_days":
                date = dayjs().add(-7, "day");
                setPeriod(dayjs().add(-7, "day").format("YYYY-MM-DD"));
                break;
            case "last_30_days":
                date = dayjs().add(-30, "day");
                break;
            case "last_90_days":
                date = dayjs().add(-90, "day");
                break;
            default:
                break;
        }
        setPeriod(date.format("YYYY-MM-DD"));
        setCurrentItem(value);
        handleClose();
    }

    return (
        <>
            <button
                id={`basic-button-${name}`}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                className="text-sm font-medium text-gray-500 hover:text-gray-900 text-center inline-flex items-center dark:hover:text-white"
                type="button"
                onClick={handleClick}
            >
                {t(currentItem)}
                <svg className="w-2.5 m-2.5 ms-1.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6">
                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 4 4 4-4"/>
                </svg>
            </button>
            <div id={`lastDaysdropdown-${name}`} className="z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow w-44">
                <Menu
                    id={`basic-menu-${name}`}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        "aria-labelledby": `basic-button-${name}`,
                    }}
                >
                    <MenuItem onClick={() => handleChangePeriod("yesterday")}>{t("yesterday")}</MenuItem>
                    <MenuItem onClick={() => handleChangePeriod("today")}>{t("today")}</MenuItem>
                    <MenuItem onClick={() => handleChangePeriod("last_7_days")}>{t("last_7_days")}</MenuItem>
                    <MenuItem onClick={() => handleChangePeriod("last_30_days")}>{t("last_30_days")}</MenuItem>
                    <MenuItem onClick={() => handleChangePeriod("last_90_days")}>{t("last_90_days")}</MenuItem>
                </Menu>
            </div>
        </>
    )
}

export default PeriodChangeMenu;