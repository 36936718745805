import {Box, Modal, SwipeableDrawer, Tab, Tabs as MuiTabs, Typography} from "@mui/material";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {Link, useSearchParams} from "react-router-dom";
import dayjs from "dayjs";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Filters from "../../components/Filters";
import useFilter from "../../hooks/useFilter";
import {ReactComponent as PinOrange} from "../../assets/icons/pin-orange.svg";
import {ReactComponent as PinGreen} from "../../assets/icons/pin-green.svg";
import {ReactComponent as PinRed} from "../../assets/icons/pin-red.svg";
import {tabStatusItems, tabTypeItems} from "../../services/constants";
import {changePitStatus} from "../RoadPits/services/fetchers";
import {AuthContext} from "../../context/AuthContext";
import FileInputField from "../../UI/InputFields/FileInputField";
import CustomButton from "../../UI/CustomButton";
import ItemDetails from "./ItemDetails";
import "react-lazy-load-image-component/src/effects/blur.css";

import("dayjs/locale/ru");

dayjs.locale("ru");

type DataType = {
    [key: string]: Record<string, any>[],
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 416,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: "1.25rem 2rem",
    borderRadius: ".5rem",
    outline: "none",
};

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    borderRadius: "4px",
    boxShadow: 24,
    p: 4,
};

type ItemDetailsType = {
    id: number,
    repaired_by: string | null,
    status: string,
    image: string | null,
    level: string | null,
    latitude: string | null,
    longitude: string | null,
    date: string | null,
    repair_image: string | null,
}

const Tasks = () => {
    const { t } = useTranslation();
    const { setNotification } = useContext(AuthContext);
    const [open, setOpen] = useState(false);
    const [openFileInputModal, setOpenFileInputModal] = useState(false);
    const [itemDetails, setItemDetails] = useState<ItemDetailsType | Record<string, any>>({});
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [data, setData] = useState<DataType>({});
    const [tempPit, setTempPit] = useState<{id?: number, status?: string}>({});
    const [imageFile, setImageFile] = useState<File | null>(null);
    const [reFetch, setReFetch] = useState(false);
    const [step, setStep] = useState(0);

    const { filters, handleSelectChange, handleCheckboxChange, handleChangeMobile, statuses, levels, regions } = useFilter();
    const stepType = searchParams.has("type") ? tabTypeItems.findIndex(tab => tab === searchParams.get("type")) : 0;
    const isMobile = window.innerWidth < 768;

    const getItems = async () => {
        try {
            setIsLoading(true);
            const res = await axios.get(`/detection/potholes/tasks${tabTypeItems[stepType] === "path_signs" ? "_znaki" : ""}/`, {params: filters});
            setData(res.data);
            if (reFetch) {
                setReFetch(false);
            }
        } catch (e) {
            console.error(e);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        console.log("filters", filters);
        getItems()
    }, [filters, stepType])

    useEffect(() => {
        if (reFetch) {
            getItems()
        }
    }, [reFetch])

    const handleClose = () => {
        setItemDetails({});
        setOpen(prev => !prev);
    }

    const handleSetItemDetails = (item: any) => {
        setItemDetails(item);
        setOpen(true);
    }

    const getButtonLabel = (status="Ожидает") => {
        switch (status) {
            case "Ожидает":
                return "to_work"
            case "Исправили":
                return ""
            case "В работе":
                return "fixed"
            default:
                return "to_work"
        }
    }

    const submitFileAndChangeStatus = () => {
        setOpenFileInputModal(false);
        onFixedHandler(tempPit.id as number, tempPit.status as string, true);
        setImageFile(null);
    }

    const onFixedHandler = async (id: number, status: string, afterModal=false) => {
        if (!afterModal && status === "В работе") {
            setOpenFileInputModal(true);
            setTempPit({id, status});
            return null;
        }
        try {
            const newStatus = status === "Ожидает"
                ? "В работе"
                : status === "В работе"
                    ? "На проверке"
                    : "Исправили"
            await changePitStatus(id, newStatus, imageFile);
            setTempPit({});
            setReFetch(true);
            handleClose();
            setNotification({
                open: true,
                type: "success",
                message: t("status_changed_successfully")
            })
        } catch (e) {
            setNotification({
                open: true,
                type: "error",
                message: t("status_change_failed")
            })
        }
        return null;
    }

    const handleChangeStep = (e: any, value: number) => {
        setStep(value);
    }
    return (
        <Box>
            <Box sx={{display: {xs: "none", md: "flex"}, justifyContent: "space-between", alignItems: "center", mb: {xs: "1rem", md: "2rem"}}}>
                <Typography variant="h1" sx={{fontSize: {xs: "1.5rem", md: "2rem"}, fontWeight: "700"}}>{t("tasks")}</Typography>
            </Box>
            <Box sx={{marginBottom: "1rem", p: ".25rem 1.5rem 1.5rem 1.5rem", borderRadius: ".5rem", background: "#fff", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)"}}>
                <Filters exclude={["status", "date", "pits_signs_gen"]}  filters={filters} regions={regions} statuses={statuses} levels={levels}
                    handleSelectChange={handleSelectChange} handleCheckboxChange={handleCheckboxChange} handleChangeMobile={handleChangeMobile}
                />
            </Box>
            <Box sx={{display: "flex", gap: "1rem"}}>
                { isMobile
                    ? (
                        <Box sx={{width: "100%", borderRadius: ".5rem", background: "#fff",
                            boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)", display: "flex", flexDirection: "column"}}
                        >
                            <MuiTabs
                                color="brandColor.primary"
                                value={step}
                                onChange={handleChangeStep}
                                sx={{mb: {xs: "1rem", md: "1.5rem"}, width: "100%"}}
                                variant="scrollable"
                                scrollButtons="auto"
                                aria-label="scrollable auto tabs example"
                            >
                                { tabStatusItems.length > 0 && tabStatusItems.map((item, index) => (
                                    <Tab color="brandColor.primary" sx={{pb: ".5rem"}} key={index} label={t(item)} />
                                ))}
                            </MuiTabs>
                            <Box sx={{p: "0 1.5rem 1.5rem 1.5rem", height: "100%", display: "flex", gap: "1rem", flexDirection: "column"}}>
                                { data[tabStatusItems[step]] && data[tabStatusItems[step]].length > 0 ? data[tabStatusItems[step]].map((item, index) => (
                                    <Box key={index} sx={{display: "flex", flexDirection: "row", gap: ".75rem", cursor: "pointer"}} onClick={() => handleSetItemDetails(item)}>
                                        <Box sx={{position: "relative", minWidth: "80px", width: {xs: "80px", md: "80px"}, height: {xs: "80px", md: "80px"}, borderRadius: "4px", overflow: "hidden"}}>
                                            <Link
                                                onClick={e => e.stopPropagation()}
                                                to={`https://backend.jol-almaty.kz${item.image}`} target="_blank"
                                            >
                                                <LazyLoadImage
                                                    alt="image"
                                                    effect="blur" // Эффект загрузки (например, "blur" или "opacity")
                                                    src={`https://backend.jol-almaty.kz${item.image}`}
                                                    style={{
                                                        width: "80px",
                                                        height: "80px"
                                                    }}
                                                />
                                            </Link>
                                        </Box>
                                        <Box>
                                            { item.repaired_by &&
                                                <Typography fontWeight={600} sx={{margin: ".5rem 0"}}>
                                                    {item.repaired_by}
                                                </Typography>
                                            }
                                            <Box
                                                fontWeight={500}
                                                className="flex w-fit gap-[.5rem] items-center mb-[.5rem]"
                                            >
                                                { item.level }
                                                { item.level === "Маленькая яма"
                                                    ? <PinGreen />
                                                    : item.level === "Средняя яма"
                                                        ? <PinOrange />
                                                        : item.level === "Большая яма"
                                                            ? <PinRed />
                                                            : null
                                                }
                                            </Box>
                                            <Typography sx={{fontSize: "14px", fontWeight: "500"}}>
                                                {dayjs(item.created_at).format("DD MMMM YYYY")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )) : (
                                    <Box sx={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "600"}}>
                                        Данных нет
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ) : data && Object.keys(data).map((key, keyIndex) => (
                        <Box key={keyIndex} sx={{overflowY: "scroll", maxHeight: "75vh", width: "calc((100%/4 - 12px))", borderRadius: ".5rem",
                            background: "#fff", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)", display: "flex", flexDirection: "column"}}
                        >
                            <Typography sx={{background: "white", boxShadow: "0px 3px 8px 0px rgba(34, 60, 80, 0.05)", paddingTop: "1.5rem", paddingBottom: "1.5rem",
                                paddingLeft: "1.5rem", fontSize: "1.25rem",
                                fontWeight: "700", position: "sticky", top: "0", zIndex: 10}}
                            >
                                {t(key)}
                            </Typography>
                            <Box sx={{p: "1rem 1.5rem 1.5rem 1.5rem", height: "100%", display: "flex", gap: "1rem", flexDirection: "column"}}>
                                { data[key] && data[key].length > 0 ? data[key].map((item, index) => (
                                    <Box key={`${keyIndex}-${index}`} sx={{display: "flex", flexDirection: "row", gap: ".75rem", cursor: "pointer"}} onClick={() => handleSetItemDetails(item)}>
                                        <Box sx={{position: "relative", minWidth: "80px", width: {xs: "80px", md: "80px"}, height: {xs: "80px", md: "80px"}, borderRadius: "4px", overflow: "hidden"}}>
                                            <Link
                                                onClick={e => e.stopPropagation()}
                                                to={`https://backend.jol-almaty.kz${item.image}`} target="_blank"
                                            >
                                                <LazyLoadImage
                                                    alt="image"
                                                    effect="blur" // Эффект загрузки (например, "blur" или "opacity")
                                                    src={`https://backend.jol-almaty.kz${item.image}`}
                                                    style={{
                                                        width: "80px",
                                                        height: "80px"
                                                    }}
                                                />
                                            </Link>
                                        </Box>
                                        <Box>
                                            { item.repaired_by &&
                                            <Typography fontWeight={600} sx={{margin: ".5rem 0"}}>
                                                {item.repaired_by}
                                            </Typography>
                                            }
                                            <Box
                                                fontWeight={500}
                                                className="flex w-fit gap-[.5rem] items-center mb-[.5rem]"
                                            >
                                                { item.level }
                                                { item.level === "Маленькая яма"
                                                    ? <PinGreen />
                                                    : item.level === "Средняя яма"
                                                        ? <PinOrange />
                                                        : item.level === "Большая яма"
                                                            ? <PinRed />
                                                            : null
                                                }
                                            </Box>
                                            <Typography sx={{fontSize: "14px", fontWeight: "500"}}>
                                                {dayjs(item.created_at).format("DD MMMM YYYY")}
                                            </Typography>
                                        </Box>
                                    </Box>
                                )) : (
                                    <Box sx={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center", fontWeight: "600"}}>
                                        Данных нет
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    ))}
            </Box>
            { isMobile
                ? (
                    <SwipeableDrawer
                        sx={{".MuiPaper-root": {borderTopLeftRadius: "8px", borderTopRightRadius: "8px"}}}
                        anchor="bottom"
                        open={open}
                        onClose={handleClose}
                        onOpen={handleClose}
                    >
                        <Box
                            sx={{ width: "auto", p: "1.25rem 1rem"}}
                            role="presentation"
                            // onClick={handleOpenMobileFilters}
                            onKeyDown={handleClose}
                        >
                            <ItemDetails
                                itemDetails={itemDetails}
                                handleClose={handleClose}
                                onFixedHandler={onFixedHandler}
                                getButtonLabel={getButtonLabel}
                            />
                        </Box>
                    </SwipeableDrawer>
                ) : (
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                    >
                        <Box sx={style}>
                            <ItemDetails
                                itemDetails={itemDetails}
                                handleClose={handleClose}
                                onFixedHandler={onFixedHandler}
                                getButtonLabel={getButtonLabel}
                            />
                        </Box>
                    </Modal>
                )
            }
            <Modal
                open={openFileInputModal}
                onClose={() => setOpenFileInputModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle}>
                    <Typography sx={{textAlign: "center", mb: "1rem"}} id="modal-modal-title" variant="h6" component="h2">
                        {t("add_photo")}
                    </Typography>
                    <FileInputField label="" value={imageFile} onChange={value => setImageFile(value)} />
                    <CustomButton label={t("submit")} onChange={submitFileAndChangeStatus} style={{width: "100%", marginTop: "1rem"}} />
                </Box>
            </Modal>
        </Box>
    )
}

export default Tasks;