import {Box, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import dayjs from "dayjs";
import Tabs from "../../../components/Tabs";
import SmallPit from "../../../assets/icons/pin-green.svg";
import MiddlePit from "../../../assets/icons/pin-orange.svg";
import BigPit from "../../../assets/icons/pin-red.svg";
import {BASE_URL} from "../../../services/constants";
import PedestrianIcon from "../../../assets/icons/pedestrian.svg";
import RoadSignIcon from "../../../assets/icons/road-sign.svg";
import PeriodChangeMenu from "./PeriodChangeMenu";

type TabDataType = {
    potholes: {
        small?: number,
        medium?: number,
        large?: number
    },
    zkani: {
        line?: number,
        arrow?: number
    }
}

const TabData = () => {
    const [step, setStep] = useState(0);
    const { t } = useTranslation();

    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<TabDataType>({
        potholes: {},
        zkani: {}
    });
    const [period, setPeriod] = useState(dayjs().add(-90, "day").format("YYYY-MM-DD"));

    useEffect(() => {
        const getChartOptions = async () => {
            try {
                setIsLoading(true);
                const res = await axios.get(`${BASE_URL}/detection/dashboard/tabs/`,
                    {params: {date_after: period, date_before: dayjs().format("YYYY-MM-DD")}}
                );
                setData(res.data);
            } catch (e) {
                console.error(e);
            } finally {
                setIsLoading(false);
            }
        }
        getChartOptions();
    }, [period])

    const tabItems = [t("pits_new"), t("signs")];

    return (
        <Box sx={{width: {xs: "100%", md: "calc(100%/3)"}, height: "fit-content", marginBottom: "1rem", borderRadius: ".5rem", background: "#fff", boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.1)"}}>
            <Typography sx={{p: "1.5rem", fontWeight: "700", fontSize: "1.25rem", lineHeight: "1rem"}}>
                {t("work_types")}
            </Typography>
            <Tabs step={step} onChange={(e, newValue) => setStep(newValue)} items={tabItems} isWidthFull />
            <Box sx={{p: "1.5rem", display: "flex", gap: "1.5rem", flexDirection: "column"}}>
                { step === 0
                    ? (
                        <>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Box>
                                    <Typography sx={{fontWeight: "700", fontSize: "1.25rem", mb: ".5rem"}}>{data.potholes.small}</Typography>
                                    <Typography sx={{color: "rgba(107, 114, 128, 1)"}}>{t("small_pits")}</Typography>
                                </Box>
                                <Box sx={{position: "relative", width: "32px", height: "32px"}}>
                                    <img
                                        alt="small pit"
                                        style={{position: "absolute", width: "100%", height: "100%", objectFit: "contain"}}
                                        src={SmallPit}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Box>
                                    <Typography sx={{fontWeight: "700", fontSize: "1.25rem", mb: ".5rem"}}>{data.potholes.medium}</Typography>
                                    <Typography sx={{color: "rgba(107, 114, 128, 1)"}}>{t("middle_pits")}</Typography>
                                </Box>
                                <Box sx={{position: "relative", width: "32px", height: "32px"}}>
                                    <img
                                        alt="small pit"
                                        style={{position: "absolute", width: "100%", height: "100%", objectFit: "contain"}}
                                        src={MiddlePit}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Box>
                                    <Typography sx={{fontWeight: "700", fontSize: "1.25rem", mb: ".5rem"}}>{data.potholes.large}</Typography>
                                    <Typography sx={{color: "rgba(107, 114, 128, 1)"}}>{t("big_pits")}</Typography>
                                </Box>
                                <Box sx={{position: "relative", width: "32px", height: "32px"}}>
                                    <img
                                        alt="small pit"
                                        style={{position: "absolute", width: "100%", height: "100%", objectFit: "contain"}}
                                        src={BigPit}
                                    />
                                </Box>
                            </Box>
                        </>
                    ) : (
                        <>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Box>
                                    <Typography sx={{fontWeight: "700", fontSize: "1.25rem", mb: ".5rem"}}>{data.zkani.line}</Typography>
                                    <Typography sx={{color: "rgba(107, 114, 128, 1)"}}>{t("pedestrian_signs")}</Typography>
                                </Box>
                                <Box sx={{position: "relative", width: "32px", height: "32px"}}>
                                    <img
                                        alt="small pit"
                                        style={{position: "absolute", width: "100%", height: "100%", objectFit: "contain"}}
                                        src={PedestrianIcon}
                                    />
                                </Box>
                            </Box>
                            <Box sx={{display: "flex", alignItems: "center", justifyContent: "space-between"}}>
                                <Box>
                                    <Typography sx={{fontWeight: "700", fontSize: "1.25rem", mb: ".5rem"}}>{data.zkani.arrow}</Typography>
                                    <Typography sx={{color: "rgba(107, 114, 128, 1)"}}>{t("road_signs")}</Typography>
                                </Box>
                                <Box sx={{position: "relative", width: "32px", height: "32px"}}>
                                    <img
                                        alt="small pit"
                                        style={{position: "absolute", width: "100%", height: "100%", objectFit: "contain"}}
                                        src={RoadSignIcon}
                                    />
                                </Box>
                            </Box>
                        </>
                    )
                }
            </Box>
            <div className="mx-[1.5rem] my-[1rem] grid grid-cols-1 items-center border-gray-200 border-t justify-between">
                <div className="flex justify-between items-center pt-5">
                    <PeriodChangeMenu
                        name="radial"
                        period={period}
                        setPeriod={setPeriod}
                    />
                    <Link
                        to="/tasks"
                        className="uppercase text-sm font-semibold inline-flex items-center rounded-lg text-blue-600
                        hover:text-blue-700 hover:bg-gray-100 px-3 py-2"
                    >
                        {t("task_board")}
                        <svg className="w-2.5 h-2.5 ms-1.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4"/>
                        </svg>
                    </Link>
                </div>
            </div>
        </Box>
    )
}

export default TabData;