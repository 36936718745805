import React, {createContext, Dispatch, FC, ReactNode, SetStateAction, useEffect, useState} from "react";
import axios from "axios";
import i18next from "i18next";
import {getMe} from "../services/fetchers";
import Loading from "../components/Loading/Loading";

export type modeTypes = "light" | "dark";

interface AuthContextInterface {
    me: Record<string, any>,
    loading: boolean,
    isLoggedIn: boolean,
    setIsLoggedIn: (value: boolean) => void,
    setMe: (value: Record<string, any>) => void,
    notification: Record<any, any>,
    setNotification: Dispatch<SetStateAction<{}>>,
    handleCloseNotification: (event?: React.SyntheticEvent | Event, reason?: string) => void,
    mode: modeTypes,
    setMode: (state: modeTypes) => void,
    toggleColorMode: () => void,
    breadcrumbs: Array<Record<string, string>>,
    setBreadcrumbs: (value: Array<Record<string, string>>) => void,
    noPermission: boolean,
    setNoPermission: (value: boolean) => void,
}

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

type AuthProviderType = {
    children: ReactNode
}

const AuthProvider: FC<AuthProviderType> = ({children}) => {
    const [me, setMe] = useState<Record<string, any>>({});
    const [notification, setNotification] = useState({});
    const [loading, setLoading] = useState<boolean>(true);
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [mode, setMode] = useState<modeTypes>(localStorage.getItem("mode") as modeTypes || "light");
    const [breadcrumbs, setBreadcrumbs] = useState<Array<Record<string, string>>>([]);
    const [noPermission, setNoPermission] = useState(false);

    const toggleColorMode = () => {
        const newMode = mode === "light" ? "dark" : "light";
        setMode(newMode);
        localStorage.setItem("mode", newMode);
    }

    const handleCloseNotification = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === "clickaway") {
            return;
        }

        setNotification({
            open: false
        });
    };

    useEffect(() => {
        // REMOVE DARK MODE
        if (localStorage) {
            if (localStorage.getItem("mode")) {
                localStorage.removeItem("mode");
                window.location.reload();
            }
        }

        axios.interceptors.request.use(config => {
            config.headers["Accept-Language"] = i18next.language;
            return config;
        });
        axios.interceptors.response.use(
            response => response,
            error => {
                if (error.response.status === 403) {
                    setNoPermission(true);
                }
                return Promise.reject(error);
            }
        );
        if (Object.keys(me).length === 0) {
            (async () => {
                try {
                    const res = await getMe();
                    setMe(res);
                    setIsLoggedIn(true);
                } catch (err) {
                    setIsLoggedIn(false);
                    console.error("You are not authorized.");
                } finally {
                    setLoading(false);
                }
            })()
        }
    }, [isLoggedIn])

    if (loading) {
        return <Loading />
    }

    return (
        <AuthContext.Provider value={{noPermission, setNoPermission, breadcrumbs, setBreadcrumbs, toggleColorMode, mode, setMode, me, setMe, loading, isLoggedIn, setIsLoggedIn,
            notification, setNotification, handleCloseNotification}}
        >
            {children}
        </AuthContext.Provider>
    )
}

export default AuthProvider;